import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography, Button, Skeleton } from "@mui/material";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateIdsDetail from "./CreateIdsDetail";
import axiosService from "../axios-api-kit/axiosService";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { Box } from "@mui/system";
import SnackBar from './SnackBar';
import NoData from "./NoData";
import { appTheme } from "../theme/colorTheme";
import Chip from '@mui/material/Chip';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { getImgSrc } from "../constants/Helper";

const CreateIdListScreen = ({ items, setOpen }) => {
  const smallDevice = useMediaQuery('(max-width:410px)');
  const extraSMallDevice = useMediaQuery('(max-width:360px)');
  const { setCreateIdData } = React.useContext(AuthContext)
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClick = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  // React.useEffect(() => {
  //   !!items && items.length !== 0 && setExpanded(`${items[0].id}panel`)
  // }, [items, setOpen]);

  return (
    <>
      {
        items.length !== 0 ? items.map((item, i) => (
          <Accordion
            key={i}
            expanded={expanded === `${item.id}panel`}
            onChange={handleChange(`${item.id}panel`)}
            sx={{ bgcolor: appTheme.color11, mb: 1, padding: 0, borderRadius: 2 }}
            disableGutters={true}
            square={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: appTheme.color1 }} />}
              aria-controls="panel-content"
              id="panel-header"
              sx={{ padding: 0, pl: 1, pr: 1, width: "100%", opacity:item.is_create?'0.5':'1', pointerEvents:item.is_create?'none':'auto' }}
            >
              <Stack
                sx={{ color: appTheme.color1, width: '100%' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box sx={{wordWrap: "break-word", textAlign: "left", overflowWrap: "anywhere", width:smallDevice?extraSMallDevice?'150px':'190px':'auto'}}>
                  <ListItem sx={{ padding: 0, width: "100%"}}>
                    <ListItemAvatar>
                      <img
                        style={{
                          width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                          alignItems: "center", verticalAlign: 'middle'
                        }}
                        src={!!getImgSrc(item?.logo_url) ? getImgSrc(item.logo_url):
                        require('../img/no-pictures.png')}
                        alt="logo"
                        loading="lazy"
                      />
                    </ListItemAvatar>
                    <ListItemText sx={{ color: appTheme.color1, padding: 0 }} primary={
                      <Typography component="div" variant="caption" display="block" gutterBottom fontSize={extraSMallDevice?11:13}>
                        {item.name}
                      </Typography>

                    } secondary={
                      <Typography
                        display="block"
                        component="span"
                        variant="caption"
                        fontSize={10}
                      >
                        {item.access_url}
                      </Typography>
                    } />
                  </ListItem>
                </Box>
                <Box>
                  <Button
                    disabled={item.is_create}
                    onClick={() => setCreateIdData(item)}
                    component={NavLink}
                    to="/createId"
                    label="createId"
                    value="createId"
                    size={extraSMallDevice?"small":"medium"}
                    startIcon={<AddCircleSharpIcon fontSize="large" sx={{mr:-1}}/>}
                    sx={{
                      pr: .7,
                      pl: .7,
                      bgcolor: appTheme.color12,
                      "&:hover": {
                        bgcolor: appTheme.color12
                      },
                      color: appTheme.color0,
                      mr: 0,
                      focusVisible: false,
                    }}
                  >
                    <Typography component="span" variant="caption" fontSize={extraSMallDevice?11:13}>Create ID</Typography>
                    
                  </Button>
                  {
                    item.is_create?<Chip icon={<ConstructionOutlinedIcon fontSize="small" />} 
                    size="small" 
                    label="Maintenance" sx={{color:appTheme.color1, fontSize:11,
                    bgcolor:'transparent',
                    fontWeight:'bold', position:'absolute', right:'6%', top:'0', zIndex:99999}}/>:''
                  }
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{opacity:item.is_create?'0.5':'1' }}>
              <CreateIdsDetail idData={item} handleClick={handleClick} />
            </AccordionDetails>
          </Accordion>
        )) : <NoData noDataText="No Data found!." />
      }
    </>
  )
}

const CreateIdList = React.memo(CreateIdListScreen)


const CreateIdsAccordianScreen = () => {
  const [open, setOpen] = React.useState(false);
  const [createIdData, setCreatedIdData] = React.useState(null);


  const getData = React.useCallback(() => {
    axiosService.getWebsitesData().then((response) => {
      setCreatedIdData(response?.data);
    }).catch(error => {
    })
  }, [])

  React.useEffect(() => {
    getData();

  }, [getData])

  return (
    <>
      <SnackBar
        setOpen={setOpen}
        open={open}
        message="Copied to clipboard!"
      />
      {
        !!createIdData ? <CreateIdList items={createIdData} setOpen={setOpen} />
          : <Box sx={{ width: '100%' }}>
            <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
            <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
          </Box>
      }



    </>
  );
};
const CreateIdsAccordian = React.memo(CreateIdsAccordianScreen);
export default CreateIdsAccordian;

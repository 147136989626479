import React, {useEffect} from "react";
import {
  CssBaseline,
  Skeleton,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import theme from "../../theme/theme";
import CarouselSlider from "../../layouts/Carousel";
import axiosService from "../../axios-api-kit/axiosService";
import { NavLink, useNavigate } from "react-router-dom";
import { localStorageKey } from "../../constants/LocalStorageKey";
import MyIdHomeDetailCard from "../../layouts/myIdsHome";
import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { appTheme } from "../../theme/colorTheme";
import runOneSignal from "../../OneSignal";
import Marquee from "../../layouts/Marquee";

const Home = () => {
  const navigate = useNavigate();
  const [bannerData, setBannerData] = React.useState(null);
  const [helplineTitle, setHelplineTitle] = React.useState('');
  const [helplineContent, setHelplineTContent] = React.useState('');

  const getBannerData = React.useCallback(() => {
    axiosService.getBanners().then((resp) => {
      setBannerData(resp.data);
    }).catch((error) => {
    })
  }, [])

  const getConfigs = React.useCallback(() => {
    axiosService.getConfigs().then((response) => {
      setHelplineTitle(response.data[0].value.title);
      setHelplineTContent(response.data[0].value.content);
    }).catch((error) => {
    })
  }, [])


  useEffect(() => {
    sessionStorage.clear(localStorageKey.SETIDSTABSTATE);
    getBannerData();
    getConfigs();
  }, [getBannerData, getConfigs]);

  useEffect(() => {
    runOneSignal();
  },[])



  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ color: appTheme.color1, paddingBottom: 2}}>
          <Box sx={{ display: 'flex', justifyContent:'space-between', alignItems:'center', background: "linear-gradient(45deg, #1a1a1a, #1a1a1a)", boxShadow:"0 0 10px rgba(0, 0, 0, 0.3)", borderRadius:2, pl:2, pb:1 }}>
            <Box sx={{pt:2}}>
              <Typography variant="h6" fontSize={15} fontWeight={"bold"} display="block" gutterBottom>
                My Wallet
              </Typography>
              <Typography variant="h5" fontWeight={"bold"} display="block" gutterBottom>
                <span style={{ color: appTheme.color6 }}>₹</span><span style={{ color: appTheme.color12 }}>{localStorage.getItem(localStorageKey.WALLETBALANCE)}</span>
              </Typography> 
            </Box>
            <Box sx={{display:'flex'}}>
              <Box
              sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1}} 
              component={NavLink}
              to="/walletDeposit"
              label="walletDeposit"
              value="walletDeposit">
                   <img
                    src={require(`../../img/deposit_icon.png`)}
                    width="80"
                    alt="logo"
                    loading="lazy"
                  /> 
                  <Typography variant="caption" display="block" gutterBottom fontSize={11} sx={{mt:-3}}>
                 Deposit
                  </Typography>
              </Box>
              <Box 
              sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1}} 
              component={NavLink}
              to="/withdrawWallet"
              label="withdrawWallet"
              value="withdrawWallet">
                  <img
                    src={require(`../../img/withdraw_icon.png`)}
                    width="80"
                    alt="logo"
                    loading="lazy"
                  />
                  <Typography variant="caption" display="block" gutterBottom fontSize={11} sx={{mt:-3}}>
                    Withdraw
                  </Typography>
                </Box>
             
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            {
              !!bannerData ? <CarouselSlider data={bannerData} navigate={navigate} navigationEndpoint={'/ids?index=1'} /> :
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                </Box>
            }

          </Box>
         <Marquee anoucmentText={'FairBaazi is the most trusted Multi Exch Platform.'}/>
          <Box>
            <MyIdHomeDetailCard />
          </Box>
        
          <Box>
            <Stack sx={{
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              mb: 2,
              mt: 2
            }}>
              {/* {
                !!helplineContent && !!helplineTitle ?
                  <>
                    <Typography variant="caption" display="block" gutterBottom sx={{ color: appTheme.color12 }}>
                      Tips & Anouncements
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {helplineTitle}<ReactWhatsapp number={`${helplineContent}`} message="" style={{
                        backgroundColor: appTheme.color12,
                        border: 0, borderRadius: 50, color: `black`, padding: '5px 10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="small" sx={{ verticalAlign: 'middle', fontSize: 18 }} /> {helplineContent}
                      </ReactWhatsapp>
                    </Typography>
                  </> : <Box sx={{ width: '100%' }}>
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                    <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                  </Box>
              } */}
            </Stack>
          </Box>
        </Container>
        <Typography variant="caption" display="block" gutterBottom>
                      {helplineTitle}<ReactWhatsapp number={`${helplineContent}`} message="" style={{
                        position: 'fixed', right: 15, bottom: 80,
                        backgroundColor: '#0FC555',
                        border: 0, borderRadius: 100, color: appTheme.color1, padding: '10px', fontSize: 12, cursor: 'pointer'
                      }}>
                        <WhatsAppIcon fontSize="large" sx={{ verticalAlign: 'middle'}} />
                      </ReactWhatsapp>
                    </Typography>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Home;

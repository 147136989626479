import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
//import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";

const HeaderBar = (props) => {
  // const userName = localStorage.getItem(localStorageKey.USERNAME);
  const handleDrawerToggle = () => {
    props.openDrawewr()
  }
  return (
    <>
      <AppBar position="fixed" sx={{
        maxWidth: "600px",
        bgcolor: appTheme.color0,
        color: appTheme.color1,
        boxShadow: '0px 0px 0px 0px',
        margin: "0 auto",
        left: 'auto',
        right: 'auto'
      }}>
        <Toolbar>
          <MenuIcon sx={{cursor:'pointer'}} onClick={handleDrawerToggle}/>
        {/* <img alt="avatar" src={!!getImgSrc(localStorage.getItem(localStorageKey.AVATAR)) ? getImgSrc(localStorage.getItem(localStorageKey.AVATAR)) :
              require('../img/defaultAvatar.jpeg')}
              style={{ width: '30px', borderRadius: 100, cursor:'pointer', marginRight:'4px' }} onClick={handleDrawerToggle}/> */}
          {/* <Typography variant="caption" component="span" fontSize={12}>
            Hi {!!userName.substring(0, userName.indexOf(' '))?userName.substring(0, userName.indexOf(' ')):userName}
          </Typography> */}
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="withdrawal details"
              color="inherit"
              component={NavLink}
              to="/withdrawDetails"
              label="withdrawDetails"
              value="withdrawDetails"
            >
              <Badge color="error">
                <AccountBalanceRoundedIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
              component={NavLink}
              to="/notifications"
              label="notifications"
              value="notifications"
            >
              <Badge badgeContent={0} color="warning">
                <NotificationsNoneRoundedIcon />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />

    </>
  );
};
const Header = React.memo(HeaderBar)
export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { Avatar, CssBaseline, Paper, Skeleton, ThemeProvider } from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from '../theme/theme';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PaymentMode from './PaymentMode';
import BankAccountPayment from './BankAccountPayment';
import axiosService from '../axios-api-kit/axiosService';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import constant from '../constants/Constatnt';
import InformationDailog from './InformationDailog';
import { AuthContext } from '../context/AuthProvider';
import SnackBar from './SnackBar';
import { appTheme } from '../theme/colorTheme';
import QrCode from './QrCode';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Payment = () => {
  const { getPaymentData } = React.useContext(AuthContext);
  const props = getPaymentData();
  const [value, setValue] = React.useState(0);
  const [bankPayment, setBankPayment] = React.useState(null)
  const [qrCodePayment, setQrCodePayment] = React.useState(null)
  const [upiPayment, setUpiPayment] = React.useState(null);
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const [uploadedScreenShotUrl, setUploadedScreenShotUrl] = React.useState("")
  const [isPaymentSelected, setIsPaymentSelected] = React.useState(false)
  const [screenshotImage, setScreenshotImage] = React.useState("");
  const ref = React.useRef();

  const [openInfo, setOpenInfo] = React.useState(false);
  const [responseStatus, setResponseStatus] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
  const [buttonTextCancel, setButtonTextCancel] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isSubmitClicked, setIsSubmitButtonClicked] = React.useState(true);

  const handleChange = (event, newValue) => {
    setSelectedPayment(null)
    setIsPaymentSelected(false);
    setScreenshotImage("");
    setUploadedScreenShotUrl("");
    setValue(newValue);
  };

  const handleCheckedUpi = React.useCallback((item, newValue) => {
    setSelectedPayment(item)
    setIsPaymentSelected(newValue)
  }, [])

  const handleCheckedBank = React.useCallback((item, newValue) => {
    setSelectedPayment(item)
    setIsPaymentSelected(newValue)
  }, [])

  const handleCheckedQrCode = React.useCallback((item, newValue) => {
    setSelectedPayment(item)
    setIsPaymentSelected(newValue)
  }, [])

  const onImageChange = (e) => {
    if (isPaymentSelected) {
      const [file] = e.target.files;
      setScreenshotImage(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("image", file);
      axiosService.uploadTransactionImage(formData).then((response) => {
        setUploadedScreenShotUrl(response?.data?.image_url);
      }).catch((error) => { })
    } else {
      alert('Please select payment')
    }

  };

  const onSubmitPayment = () => {
    setIsSubmitButtonClicked(false);
    const payload = new URLSearchParams()
    if (props.transactionFor !== constant.transactionFor.walletDeposit) {
      if (props.transactionFor === constant.transactionFor.createIdDeposit) {
        payload.append('website_id', props.user_website_id);
        payload.append('preferred_username', props.preferred_username);
      } else {
        payload.append('user_website_id', props.user_website_id);
      }
      payload.append('wallet_balance_used', props.wallet_balance_used);
      payload.append('payment_method_amount', props.payment_method_amount);
    }
    payload.append('requested_amount', props.requested_amount);
    payload.append('payment_method_id', selectedPayment.id);
    payload.append('image_url', uploadedScreenShotUrl);

    axiosService.user_transaction(payload, props.transactionFor).then((response) => {
      setResponseStatus('success');
      setStatusMessage("Your request has been submitted successfully!");
      setButtonTextConfirm('Ok')
      setButtonTextCancel('Cancel')
      setNavigateUrl('/');
      setOpenInfo(true);
      setIsSubmitButtonClicked(true);
    }).catch((error) => {
      setResponseStatus('error');
      setStatusMessage("Your request has been failed!");
      setButtonTextConfirm('Ok')
      setButtonTextCancel('Cancel')
      setNavigateUrl('');
      setOpenInfo(true);
      setIsSubmitButtonClicked(true)
    })

  }

  const getData = () => {
    Promise.all([
      axiosService.getPaymentMethodTypes(),
      axiosService.getCompanyMethodTypes()
    ])
      .then(([paymentMethods, companyPaymentMethods]) => {
        const paymentData = companyPaymentMethods.data.filter((companyMethod) => {
          return paymentMethods.data.some(paymentMethod => {
            return paymentMethod.name.toLocaleLowerCase() === companyMethod.name.toLocaleLowerCase() && companyMethod.is_active;
          })
        });
        const bankPaymentOnly = paymentData.filter(object => {
          return object.type.toLocaleLowerCase() === 'bank';
        });
        setBankPayment(bankPaymentOnly);

        const qrCodePaymentOnly = paymentData.filter(object => {
          return object.type.toLocaleLowerCase() === 'qr';
        });
        setQrCodePayment(qrCodePaymentOnly);

        const upiPaymentOnly = paymentData.filter(object => {
          return object.type.toLocaleLowerCase() !== 'bank' && object.type.toLocaleLowerCase() !== 'qr';
        });
        setUpiPayment(upiPaymentOnly)
      })
      .catch(error => { });
  }


  const handleClick = React.useCallback(() => {
    setOpenSnackbar(true);
  }, []);

  const tabsData = [
    {
      "id": "qr",
      "data": qrCodePayment,
      "element": <QrCode data={qrCodePayment} handleChecked={handleCheckedQrCode} handleClick={handleClick} />
    },
    {
      "id": "upi",
      "data": upiPayment,
      "element": <PaymentMode data={upiPayment} handleChecked={handleCheckedUpi} handleClick={handleClick} />
    },
    {
      "id": "bank",
      "data": bankPayment,
      "element": <BankAccountPayment data={bankPayment} handleChecked={handleCheckedBank} handleClick={handleClick} />
    }
  ];

  let tabIndex = -1;


  React.useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <SnackBar
        setOpen={setOpenSnackbar}
        open={openSnackbar}
        message="Copied to clipboard!"
      />
      {!!openInfo && <InformationDailog
        openInfo={openInfo}
        setOpenInfo={setOpenInfo}
        status={responseStatus}
        statusMessage={statusMessage}
        buttonTextConfirm={buttonTextConfirm}
        buttonTextCancel={buttonTextCancel}
        navigateUrl={navigateUrl} />}
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Container>
          {
            !!bankPayment && !!upiPayment ?
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                  <Typography variant="h5" gutterBottom component="div" sx={{ color: appTheme.color1 }}>
                    Pay ₹{props.payment_method_amount}
                  </Typography>
                </Box>
                <Box sx={{ width: '100%', color: appTheme.color1 }}>
                  <AppBar position="static" sx={{ flexGrow: 1, backgroundColor: appTheme.color11, padding: .5, borderRadius: 2 }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                      TabIndicatorProps={{
                        hidden: true,
                      }}
                      sx={{
                        color: appTheme.color1,
                        '& button.Mui-selected': { border: 2, borderColor: appTheme.color12, borderRadius: 1, padding: 1.5 },
                      }}
                    >
                      {
                        qrCodePayment.length !== 0 && <Tab icon={<Avatar alt="test avatar" src={require('../img/qr.png')} />} label="QR Code" {...a11yProps("bank")} sx={{ color: appTheme.color1 }} />
                      }
                      {
                        upiPayment.length !== 0 && <Tab icon={<Avatar alt="test avatar" src={require('../img/upi.png')} />} label="UPI" {...a11yProps("upi")} sx={{ color: appTheme.color1 }} />
                      }

                      {
                        bankPayment.length !== 0 && <Tab icon={<Avatar alt="test avatar" src={require('../img/bank_transfer.png')} />} label="Bank Transfer" {...a11yProps("bank")} sx={{ color: appTheme.color1 }} />
                      }
                    </Tabs>
                  </AppBar>
                  {
                    tabsData.map(tab => {
                      if (tab.data.length !== 0) {
                        tabIndex = tabIndex + 1;
                      }
                      return tab.data.length !== 0 && <TabPanel key={tab.id} value={value} index={tabIndex}>
                        <Paper sx={{ backgroundColor: 'transparent' }}>
                          {tab.element}
                        </Paper>
                      </TabPanel>
                    })
                  }

                </Box>
                <Stack spacing={2} sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  {
                    !!screenshotImage ? <Box sx={{ position: 'relative', width: '190px', mt: 3, border: `1px dashed ${appTheme.color6}` }}>
                      {
                        !!screenshotImage ? <ClearRoundedIcon
                          onClick={() => {
                            setScreenshotImage("");
                            setUploadedScreenShotUrl("");
                          }}
                          fontSize='large' fontWeight='800'
                          sx={{ color: appTheme.color0, borderRadius: 100, cursor: "pointer", bgcolor: appTheme.color1, position: 'absolute', right: -10, top: -15 }} /> : ''
                      }
                      <img src={screenshotImage} alt="" width="188" />
                    </Box> : ''
                  }
                  {
                    !!screenshotImage ?
                      <Button disabled={!isPaymentSelected || !uploadedScreenShotUrl} sx={{
                        color: appTheme.color0, bgcolor: appTheme.color12,
                        "&:hover": {
                          bgcolor: appTheme.color12
                        },
                        focusVisible: false,
                        pb: 1, pt: 1, pl: 5, pr: 5,
                      }} component="label" onClick={() => {
                        if (isSubmitClicked) {
                          onSubmitPayment()
                        }

                      }}>Submit</Button> :
                      <Button disabled={!isPaymentSelected && !uploadedScreenShotUrl} sx={{
                        color: appTheme.color0, bgcolor: appTheme.color12,
                        "&:hover": {
                          bgcolor: appTheme.color12
                        },
                        focusVisible: false,
                        pb: 1, pt: 1
                      }} component="label">
                        <PhotoCamera />&nbsp;Upload payment screenshot
                        <input hidden ref={ref} accept="image/*" multiple type="file" onChange={onImageChange} />
                      </Button>
                  }

                </Stack>
              </>
              : <Box sx={{ width: '100%' }}>
                <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton sx={{ backgroundColor: appTheme.color11 }} />
                <Skeleton animation="wave" sx={{ backgroundColor: appTheme.color11 }} />
              </Box>
          }
        </Container>



      </ThemeProvider>
    </React.Fragment>

  );
}

const PaymentScreen = React.memo(Payment)
export default PaymentScreen;
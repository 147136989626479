import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import NoData from "./NoData";
import constant from "../constants/Constatnt";
import { dateConvert, getImgSrc } from "../constants/Helper";
import { useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: appTheme.color11,
  ...theme.typography.body2,
  padding: 0,
  textAlign: "center",
  color: appTheme.color1,
}));
const PassbookList = (props) => {
  const openTransactionDetailsDailog = (selectedTransaction) => {
    props.onClick(selectedTransaction);
  }


  const transactionList = {}
  transactionList["id"] = props?.itemList?.id
  transactionList["remarks"] = props?.itemList?.reason_remarks
  transactionList["transactionRequest"] =
    props?.itemList?.transactionable_type === constant.transactionType.depositWallet || props?.itemList?.transactionable_type === constant.transactionType.withdrawWallet ? 'WalletRequest' :
      props?.itemList?.transactionable_type === constant.transactionType.depositId || props?.itemList?.transactionable_type === constant.transactionType.withdrawId ? 'ExistingIdRequest' :
        props?.itemList?.transactionable_type === constant.transactionType.changeIdPasswordRequest ? 'ChangePasswordRequest' : 'CreateIdRequest';
  transactionList["transactionType"] =
    props?.itemList?.transactionable_type === constant.transactionType.withdrawWallet || props?.itemList?.transactionable_type === constant.transactionType.withdrawId ? 'Withdraw' :
      props?.itemList?.transactionable_type === constant.transactionType.depositWallet || props?.itemList?.transactionable_type === constant.transactionType.depositId ? 'Deposit' :
        props?.itemList?.transactionable_type === constant.transactionType.changeIdPasswordRequest ? 'ChangePassword' : '';
  transactionList["status"] = props?.itemList?.status;
  transactionList["amount"] = props?.itemList?.transactionable?.requested_amount;
  transactionList["userId"] = props?.itemList?.user_id;
  transactionList["referenceNo"] = props?.itemList?.transaction_number;
  transactionList["requestedDate"] = dateConvert(props?.itemList?.created_at);
  transactionList["logoUrl"] = transactionList?.transactionRequest === 'CreateIdRequest' ? props.itemList.transactionable.website?.logo_url :
    transactionList?.transactionRequest === 'ExistingIdRequest' ? props.itemList.transactionable.user_website?.website.logo_url :
      transactionList?.transactionRequest === 'ChangePasswordRequest' ? props.itemList.transactionable.user_website?.website.logo_url : '';
  transactionList["demo_id"] = transactionList?.transactionRequest === 'CreateIdRequest' ? props.itemList.transactionable.website?.demo_id :
    transactionList?.transactionRequest === 'ExistingIdRequest' ? props.itemList.transactionable.user_website?.website.demo_id :
      '';
  transactionList["brandName"] = transactionList?.transactionRequest === 'CreateIdRequest' ? props.itemList.transactionable.website?.name :
    transactionList?.transactionRequest === 'ExistingIdRequest' ? props.itemList.transactionable.user_website?.website.name :
      transactionList?.transactionRequest === 'ChangePasswordRequest' ? props.itemList.transactionable.user_website?.website.name : '';
  transactionList["userName"] = transactionList?.transactionRequest === 'ExistingIdRequest' ? props.itemList.transactionable.user_website?.username :
  transactionList?.transactionRequest === 'ChangePasswordRequest' ? props.itemList.transactionable.user_website?.username:''
  
  return (


    <Item>
      <Stack
        sx={{ color: appTheme.color1, width: '100%', cursor: 'pointer' }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          openTransactionDetailsDailog(transactionList)
        }}
      >
        <Box>
          <ListItem
            sx={{ color: appTheme.color1, padding: .5, pl: 1, pr: 1 }}
            alignItems="flex-start"
          >
            <ListItemAvatar>
              <img
                style={{
                  width: '45px', borderRadius: 50, backgroundColor: appTheme.color0, padding: 5, justifyContent: "center",
                  alignItems: "center", verticalAlign: 'middle'
                }}
                src={!!getImgSrc(transactionList?.logoUrl) ? getImgSrc(transactionList.logoUrl) : require('../img/no-pictures.png')}
                alt="logo"
                loading="lazy"
              />
            </ListItemAvatar>
            <ListItemText
            sx={{maxWidth:'250px'}}
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="div"
                    display="block"
                    variant="caption"
                    fontSize={11}
                  >
                    {
                      transactionList.transactionRequest === 'WalletRequest' && transactionList.transactionType === 'Deposit' ?
                        'Deposit to wallet' :
                        transactionList.transactionRequest === 'ExistingIdRequest' && transactionList.transactionType === 'Deposit' ?
                          `Deposit to ${transactionList.brandName} Id` :
                          transactionList.transactionRequest === 'WalletRequest' && transactionList.transactionType === 'Withdraw' ?
                            'Withdraw from wallet' :
                            transactionList.transactionRequest === 'ExistingIdRequest' && transactionList.transactionType === 'Withdraw' ?
                              `Withdraw from ${transactionList.brandName} Id` :
                              transactionList.transactionRequest === 'CreateIdRequest' ?
                                `Create ${transactionList.brandName} Id` :
                                transactionList.transactionRequest === 'ChangePasswordRequest' ? `Changed ${transactionList.brandName} password` : ''
                    }

                  </Typography>
                  {/* <Typography
                  display="block"
                  component="span"
                  variant="caption"
                  fontSize={10}
                >
                  User ID: {transactionList?.userId}
                </Typography> */}
                <Typography
                    display="block"
                    component="span"
                    variant="caption"
                    color={appTheme.color6}
                    fontSize={10}
                    sx={{textTransform:'uppercase'}}
                  >
                    {transactionList.userName}
                  </Typography>
                  <Typography
                    display="block"
                    component="span"
                    variant="caption"
                    color={appTheme.color6}
                    fontSize={10}
                  >
                    {transactionList.requestedDate}
                  </Typography>
                  <Typography
                    component="div"
                    display="inline-block"
                    variant="caption"
                    color={`white`}
                    fontSize={10}
                    sx={{backgroundColor:appTheme.color17, textTransform:'uppercase', padding:.5, borderRadius:2}}
                  >
                    {transactionList.remarks}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </Box>
        <Box sx={{
          width: '120px',
          pr: 2,
          ml: 1
        }}>
          <Typography
            sx={{ display: "block", textAlign: 'right' }}
            component="span"
            variant="caption"
          >
            {transactionList?.transactionType === 'Deposit' ? `+${transactionList.amount}` :
              transactionList?.transactionType === 'Withdraw' ? `- ${transactionList.amount}` :
                transactionList?.transactionType === 'ChangePassword' ? '' : `${transactionList.amount}`}
          </Typography>
          <Typography
            sx={{ display: "block", textAlign: 'right' }}
            component="span"
            variant="caption"
            color={
              transactionList?.status.toLocaleLowerCase() === 'rejected' ? appTheme.color16 :
                transactionList?.status.toLocaleLowerCase() === 'pending' ? appTheme.color12 :
                  appTheme.color13
            }
          >
            {
              transactionList?.status.toLocaleLowerCase() === 'rejected' ? 'Rejected' :
                transactionList?.status.toLocaleLowerCase() === 'pending' ? 'Pending' :
                  'Approved'
            }
          </Typography>
        </Box>
      </Stack>


    </Item>



  );
};

const PassbookCardScreen = (props) => {
  let navigate = useNavigate();
  const showTransactionDetails = (selectedTransaction) => {
    navigate({
      pathname: '/transactionsDetails',
      search: `?id=${selectedTransaction.id}`,
    })
  }
  React.useEffect(() => {

  }, [])
  return (
    <>
      <Box sx={{ width: "100%" }} >
        <Stack spacing={1}>
          {!!props?.transactionsData && props?.transactionsData.length !== 0 ? props?.transactionsData.map((item) => (
            <PassbookList itemList={item} key={item.id} onClick={showTransactionDetails} />
          )) : <NoData noDataText="You don't have any transaction yet." />
          }



        </Stack>
      </Box>
    </>
  );
};

const PassbookCard = React.memo(PassbookCardScreen);

export default PassbookCard;

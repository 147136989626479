
const constant = {
    websiteName:"FairBaazi",
    baseUrl:"https://fbcricxyz.in/",
    whatsAppNumber:"+919333600000",
    instagramUrl:"https://www.instagram.com/fairbaazi/",
    telegramUrl:"https://api.whatsapp.com/send/?phone=919333600000&text&type=phone_number&app_absent=0",
    youTubeUrl:"https://www.youtube.com/channel/UCcaunrHXS3IK8UEhQv34uIQ",
    apkUrl:"https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/fairbaazi/FairBaaziV2.apk",landingPageLogoSize:"70px",
    logoPath:"logo.jpeg",
    minDepositCoins:100,
    minWithdrawalCoins:500,

    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createId: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest'
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    }
}

export default constant;
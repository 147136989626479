import React from "react";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import Paper from '@mui/material/Paper';
import { NavLink } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from '../theme/theme'
import { appTheme } from "../theme/colorTheme";
const FooterMenu = (props) => {
  const ref = React.useRef(null);
  React.useEffect(() => { });

  const navLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? appTheme.color12 : appTheme.color6,
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ pb: 7}} ref={ref}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0,  maxWidth: "600px", margin: "0 auto"}}
          elevation={3}
        >
          <BottomNavigation showLabels sx={{ backgroundColor: appTheme.color0 }}>
            <BottomNavigationAction
              component={NavLink}
              style={navLinkStyle}
              to="/home"
              label="Home"
              value="Home"
              icon={<HomeRoundedIcon />}
            />
            <BottomNavigationAction
              component={NavLink}
              style={navLinkStyle}
              to="/offers"
              label="Offers"
              value="Offers"
              icon={<LocalOfferRoundedIcon />}
            />
            <BottomNavigationAction
              component={NavLink}
              style={navLinkStyle}
              to="/passbook"
              label="Passbook"
              value="Passbook"
              icon={<AccountBalanceWalletRoundedIcon />}
            />
            <BottomNavigationAction
              component={NavLink}
              style={navLinkStyle}
              to="/ids"
              label="Ids"
              value="Ids"
              icon={<BadgeRoundedIcon />}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </ThemeProvider>

  );
};

export default FooterMenu;

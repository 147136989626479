import React from 'react'
import CheckboxLabels from '../components/customComponent/Checkbox';
import { getImgSrc } from '../constants/Helper';
import { Button, Typography } from '@mui/material';
import { appTheme } from '../theme/colorTheme';
import DownloadIcon from '@mui/icons-material/Download';

const QrCode = (props) => {
    const [selectedPaymentType, setSelectedPaymentType] = React.useState([])
    const download = async (imgUrl) => {
        console.log(imgUrl)
        const data = await fetch(imgUrl);
        const blob = await data.blob();
        const blobURL = window.URL.createObjectURL(new Blob([blob]))
        const fileName = imgUrl.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };
    return (

        <>


            {props.data.map((row) => (
                <div key={row.id} style={{ display: 'flex', color: appTheme.color1, justifyContent: 'space-between', alignItems: 'center', fontSize: 11, borderBottom: '1px solid white', padding: '10px 0' }}>
                    <div style={{ display: 'flex', color: appTheme.color1, alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                            <CheckboxLabels checked={selectedPaymentType?.id === row?.id} onChange={(e, newValue) => {
                                if (newValue) {
                                    setSelectedPaymentType(row);
                                    props.handleChecked(row, newValue);
                                } else {
                                    setSelectedPaymentType(null)
                                    props.handleChecked(null, newValue);
                                }
                            }} />

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={!!getImgSrc(row?.logo_url) ? getImgSrc(row.logo_url) : require('../img/no-pictures.png')}
                                style={{ verticalAlign: 'middle', borderRadius: 10, width: "100px" }}

                                alt="logo"
                                loading="lazy"
                            />&nbsp;&nbsp; <Typography variant="caption" gutterBottom fontSize={14} fontWeight={"bold"}>{row.account_display_name}</Typography>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>

                        <Button sx={{
                            color: appTheme.color0, bgcolor: appTheme.color12,
                            "&:hover": {
                                bgcolor: appTheme.color12
                            },
                            focusVisible: false,
                            ml:.5,
                            pb: .5, pl: .5, pt: .5, pr: 1,
                            fontSize: 10
                        }} component="label" onClick={() => {
                            download(row?.logo_url);

                        }}><DownloadIcon fontSize='small' /> Download Qr</Button>
                    </div>
                </div>
            ))}

        </>



    )
}

export default QrCode